import './distributiva_tiendas_pa.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Asgard } from '../src/components/Asgard/Asgard';
import Button from '../src/components/Buttons/Buttons';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import AnounceCovid from '../src/components/AnounceCovid/AnounceCovid';
import SEO from '../src/helpers/seo';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';

class DistributivaTiendasPa extends Component {
  state = {
    world: '',
    tipoProducto: '',
    initialFilterStateMundo: true,
    initialFilterStateTipoProducto: true,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('MUNDO', this.props.pageContext.locale))
      this.setState({ world: name, initialFilterStateMundo: false });
    else if (tag === tt('TIPO DE PRODUCTO', this.props.pageContext.locale))
      this.setState({ tipoProducto: name, initialFilterStateTipoProducto: false });
  };

  resetFilter = () => {
    this.setState({
      initialFilterStateMundo: true,
      initialFilterStateTipoProducto: true,
      world: '',
      tipoProducto: '',
    });
  };

  render() {
    const tiendasData = {
      text: this.props.data.tiendasHeaderBlock,
      image: {
        url: this.props.data.tiendasHeaderImageBlock.localImage.childImageSharp.fluid,
      },
      cards: this.props.data.allTiendasCardsSubBlock.edges,
      breadcrumb: this.props.data.tiendasBreadCrumbBlock,
      promotions: this.props.data.allTiendasPromotedSubBlock.edges,
    };
    const heimdallData = {
      name: tiendasData.text.title.toUpperCase(),
      subtitle: tiendasData.text.subtitle,
      image: tiendasData.image,
      imageMobile: {
        url: this.props.data.allTiendasMobileImage.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const AsgardData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png',
      text: `${tiendasData.cards.length} ${tt('tiendas', this.props.pageContext.locale)}`,
      filters: [
        {
          tag: tt('MUNDO', this.props.pageContext.locale),
          options: [
            { value: tt('Mediterrània', this.props.pageContext.locale), label: 'Mediterrània' },
            { value: tt('China', this.props.pageContext.locale), label: 'China' },
            { value: tt('Polynesia', this.props.pageContext.locale), label: 'Polynesia' },
            { value: tt('México', this.props.pageContext.locale), label: 'México' },
            {
              value: tt('SésamoAventura', this.props.pageContext.locale),
              label: 'Sesamo Aventura',
            },
            { value: tt('Far West', this.props.pageContext.locale), label: 'Far West' },
          ],
        },
        {
          tag: tt('TIPO DE PRODUCTO', this.props.pageContext.locale),
          options: [
            { value: tt('Souvenirs', this.props.pageContext.locale), label: 'souvenirs' },
            { value: tt('Golosinas', this.props.pageContext.locale), label: 'candies' },
            { value: tt('Artesania', this.props.pageContext.locale), label: 'crafts' },
            { value: tt('Juegos', this.props.pageContext.locale), label: 'games' },
            { value: tt('Moda', this.props.pageContext.locale), label: 'fashion' },
            {
              value: tt('Fotografía-atracciones', this.props.pageContext.locale),
              label: 'photo_ride',
            },
            {
              value: tt('Fotografía-especiales', this.props.pageContext.locale),
              label: 'photo_special',
            },
          ],
        },
      ],
    };

    const freyaDataShops = {
      cards: tiendasData.cards
        .map((shop) => ({
          image: shop.node.localImage.childImageSharp.fluid,
          altImage: shop.node.image_alt,
          imgsize: shop.node.imgsize,
          title: shop.node.title,
          disabled: shop.node.disabled,
          description: shop.node.description,
          seeMore: shop.node.seeMore,
          located:
            shop.node.located === 'Sesamo Aventura'
              ? 'SésamoAventura'
              : shop.node.located.charAt(0).toUpperCase() + shop.node.located.slice(1),
          features: shop.node.features.split(' '),
          buttons: {
            cta: shop.node.buttons.cta,
            size: shop.node.buttons.size,
            color: shop.node.buttons.color,
            ctaText: shop.node.buttons.ctaText,
          },
        }))
        .filter((item) => {
          if (
            this.state.initialFilterStateMundo === true &&
            this.state.initialFilterStateTipoProducto
          )
            return item;
          if (this.state.world !== '' && this.state.tipoProducto === '')
            return item.located.toLowerCase() === this.state.world.toLowerCase();
          if (this.state.world === '' && this.state.tipoProducto !== '')
            return item.features.includes(this.state.tipoProducto.toLowerCase());
          if (this.state.world !== '' && this.state.tipoProducto !== '') {
            return (
              item.located.toLowerCase() === this.state.world.toLowerCase() &&
              item.features.includes(this.state.tipoProducto.toLowerCase())
            );
          }
        }),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: tiendasData.promotions.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        title: shop.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: shop.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: shop.node.ctaText,
        },
      })),
    };
    const buttons = {
      cta: tt('/entradas', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };
    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allShopsSeoData.edges[0].node._0.title}
          description={this.props.data.allShopsSeoData.edges[0].node._1.description}
          // canonicalLink={`https://www.portaventuraworld.com${this.props.location.pathname}`}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allTiendasRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.tiendasHeaderImageBlock.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="shops-content">
              <div className="Asgard-box">
                <Asgard
                  locale={this.props.pageContext.locale}
                  filterStatusMundo={this.state.initialFilterStateMundo}
                  filterStatusTipoProducto={this.state.initialFilterStateTipoProducto}
                  resetFilter={this.resetFilter}
                  filterChange={this.handleFilter}
                  data={AsgardData}
                />
              </div>
              <div className="shops-buy">
                <Button
                  link={buttons.cta}
                  size={buttons.size}
                  color={buttons.color}
                  text={buttons.ctaText}
                />
              </div>
              <BlueArrow />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDistTiendasBreadCrumbBlock.edges[0].node.name}
              />
              <AnounceCovid
                h2
                text={tt(
                  'EL FUNCIONAMIENTO HABITUAL DE CIERTAS TIENDAS PUEDE VERSE ALTERADO O LIMITADO TEMPORALMENTE EN ATENCIÓN A LAS RESTRICCIONES DERIVADAS DE LAS MEDIDAS PREVENTIVAS COVID-19 IMPLEMENTADAS.',
                  this.props.pageContext.locale
                )}
              />
              <div className="shop-freya-content">
                <Freya data={freyaDataShops} />
              </div>
              <div className="shops-content-promo">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
DistributivaTiendasPa.propTypes = {
  ourShopsData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default DistributivaTiendasPa;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query tiendasPortAventuraQuery($locale: String!) {
    tiendasHeaderBlock(park: { eq: "PortAventura" }, lang: { eq: $locale }) {
      title
      subtitle
    }
    allShopsSeoData(filter: { tag: { eq: "tiendas_pa" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    tiendasHeaderImageBlock(park: { eq: "PortAventura" }) {
      image
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allTiendasCardsSubBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          image_alt
          title
          disabled
          description
          seeMore
          located
          features
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allTiendasPromotedSubBlock(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allTiendasMobileImage(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDistTiendasBreadCrumbBlock(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allTiendasRichData(filter: { extra: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;
